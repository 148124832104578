import { Component, OnDestroy, inject } from '@angular/core';
import { Language } from '@spartacus/core';
import { DropdownDataSource, DropdownItem } from '@util/components/dropdown/dropdown-data-source.class';
import { combineLatest, of, Subscription, timer } from 'rxjs';
import { skip, switchMap, take } from 'rxjs/operators';
import { KurzLanguageService } from '../../services/kurz-language.service';
import { CustomPreHeaderService } from '../custom-pre-header/custom-pre-header.service';

interface KurzLanguage extends Partial<Language> {
  selected?: boolean;
}

@Component({
  selector: 'app-custom-language-selector',
  templateUrl: './custom-language-selector.component.html',
  styleUrls: ['./custom-language-selector.component.scss']
})
export class CustomLanguageSelectorComponent implements OnDestroy {

  private customPreHeaderService = inject(CustomPreHeaderService);
  private kurzLanguageService = inject(KurzLanguageService);

  private selectItemSub: Subscription;

  allLanguages$ = combineLatest([
    this.kurzLanguageService.getAll(),
    this.kurzLanguageService.getActive(),
  ]).pipe(
    switchMap(([all, activeISO]) => {

      const allKurzLangs: KurzLanguage[] = [];
      all.forEach(lang => {
        const localLang: KurzLanguage = Object.assign({}, lang);
        localLang.selected = lang.isocode === activeISO;
        allKurzLangs.push(localLang);
      });

      this.selectItemSub?.unsubscribe();

      const items: DropdownItem[] = [];

      allKurzLangs.forEach(lang => {
        items.push({ value: lang.isocode, label: lang.nativeName, selected: lang.selected });
      });

      this.dropdownDataSource = new DropdownDataSource({
        multi: false,
        claimSpace: true
      }, items);

      this.selectItemSub = this.dropdownDataSource.selectedItems$.pipe(skip(1)).subscribe(item => {
        const isoCode = item[0]?.value;
        if (isoCode) {
          this.kurzLanguageService.setActive(isoCode);
          this.kurzLanguageService.setStandardLanguageInCustomer(isoCode).subscribe({error: err => console.error('Error updating default language in customer', err)});
          timer(500).pipe(take(1)).subscribe(() => { // NOSONAR
            this.customPreHeaderService.close();
          });
        }
      });

      return of(allKurzLangs);
    })
  );


  dropdownDataSource: DropdownDataSource;

  ngOnDestroy(): void {
    this.selectItemSub?.unsubscribe();
  }

}
