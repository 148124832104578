<ng-template let-val #inputTemplate>
  <div class="dropdown-input-value-template-container">
    <app-icon
      iconType="GLOBE"
      iconSize="SMALL"
      [colorTheme]="['BLACK', 'BLACK', {colorThemeBackgroundColor: 'black'}]"
    ></app-icon>
    <div>{{val}}</div>
  </div>

</ng-template>

<ng-container *ngIf="allLanguages$ | async as allLanguages">
  <ng-container *ngIf="allLanguages.length > 1">
    <app-dropdown
      [dropdownInputValueTemplate]="inputTemplate"
      [dataSource]="dropdownDataSource"
    ></app-dropdown>
  </ng-container>
</ng-container>